.App {
  text-align: center;
}
.MuiButtonBase-root.MuiButton-root.MuiButton-contained.sc-bdfBQB.MuiButton-containedPrimary {
  background-color: #0bf116!important;
  color:#361574;
  font-weight:700;
  border-radius: 25px;
  margin-bottom:-2rem;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-contained.sc-eCstlR.MuiButton-containedPrimary.Mui-disabled.Mui-disabled {
  visibility:hidden;
}
.MuiButtonBase-root.MuiButton-root.MuiButton-contained.sc-eCstlR.MuiButton-containedPrimary{
  background-color: #0bf116!important;
  color:#361574;
  font-weight:700;
  border-radius: 25px;
}
.MuiButtonBase-root.MuiButton-root.MuiButton-contained.sc-hKgJUU{
  background-color: #0bf116!important;
  color:#361574;
  font-weight:700;
  border-radius: 25px;
  margin-bottom:1rem;
}
.MuiButtonBase-root.MuiButton-root.MuiButton-contained.sc-hKgJUU a{
  background-color: #8d12d4!important;
  color:#361574;
  font-weight:700;
  border-radius: 25px;
  margin-bottom:1rem;
}